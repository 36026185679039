import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AdsGETApi, AdsGETApiAuth } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';
import store from '../../../redux/store';
import NotFound from '../../NotFound';
import FirstScreenExam from '../ExamDetails';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
export default function StartDemoExam(props) {
  const dispatch = useDispatch()
  const location = useLocation()
  const [ExamData, setAllExamList] = React.useState({});
  const [ExamFetch, setExamFetch] = React.useState(false);
  const [NotFoundData, setNotFound] = React.useState(false)
  const [reload, setReload] = React.useState(false)
  const [choose, setChoose] = React.useState(0)
  const [AnswerArray, setAnswerArray] = React.useState([]);
  const [ResultData, setResultData] = React.useState({ meta: {} });

  const fetchExam = (slug) => {
    setExamFetch(false)
    setReload(true)
    setNotFound(false)
    const id = store.getState().LoginState.id
    AdsGETApi({ slug: slug }, slugs.db_slug_demo_exam)
      .then((res) => {
        if (res.data.length == 1) {
          if (res.data[0].meta.exam_question_text !== undefined)
            setAllExamList(res.data[0])
          else {
            setNotFound(true)
          }

          setExamFetch(true)
          // })
          // .finally(() => {
          setReload(false)
          // })
        }
        else {
          setNotFound(true)
          setExamFetch(true)
          setReload(false)
        }
      })
      .catch(err => {
        console.log(err)
        console.log(err.response.data)
      })

  }
  const [finishStatus, setfinishStatus] = React.useState(false);
  const handle = useFullScreenHandle();

  useEffect(() => {
    console.log("asdsahgdh")
    fetchExam(location.pathname.slice(slugs.start_demo_exam.length + 1))
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    }
    else
      handle.enter()
  }, [])

  return (
    <div>
      <FullScreen handle={handle}>
        <div style={{ backgroundColor: "#eee", minHeight: "100vh" }}>
          <div>
            <div className="form-view m-3">
              {ExamFetch &&
                <>
                  {NotFoundData ?
                    <NotFound /> :
                    <>
                      <FirstScreenExam
                        ExamData={ExamData}
                        setReload={setReload}
                        choose={choose}
                        setChoose={setChoose}
                        AnswerArray={AnswerArray}
                        setAnswerArray={setAnswerArray}
                        ResultData={ResultData} setResultData={setResultData}
                      />
                    </>
                  }
                </>
              }
            </div>
            {/* </div> */}
          </div>
          {reload &&
            <div className="modal" style={{ padding: "50vw" }}>
              <div className="dot-pulse"></div>
            </div>
          }
        </div>
      </FullScreen>
    </div>
  )
}