import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { slugs } from '../../../Constant/slugs';
import { sideBarImage } from '../../../Constant/api';

export default function Dashboard() {
	const data = [
		{
			slug: slugs.exam,
			text: "All Exam",
			image: "https://api.anskubt.com/wp-content/uploads/2023/06/AllExam.jpeg"
		},
		{
			slug: slugs.demo_exam,
			image: "https://api.anskubt.com/wp-content/uploads/2023/06/DemoExam.jpeg"
		},
		{
			slug: slugs.chapter_wise_exam,
			image: "https://api.anskubt.com/wp-content/uploads/2023/06/ChapterExam.jpeg"
		},
		{
			slug: slugs.exam_result_list,
			image: "https://api.anskubt.com/wp-content/uploads/2023/06/ExamResults.jpeg"
		},
		{
			slug: slugs.chapter_wise_exam_result_list,
			image: "https://api.anskubt.com/wp-content/uploads/2023/06/ChapterResults.jpeg"
		},
		{
			slug: slugs.books,
			image: "https://api.anskubt.com/wp-content/uploads/2023/06/Books.jpeg"
		},
	]
	const HomeData = [
		{
			slug: slugs.demo_exam,
			text: "Demo Exam",
			params: {},
			image: ""
		},
		{
			slug: "/classroom",
			text: "Class Room",
			params: {},
			image: "",
		},
		{
			slug: slugs.exam,
			text: "UBT",
			image: "https://api.letangmultitechnical.com/wp-content/uploads/2024/01/ubt.jpeg",
			params: {
				type: "UBT"
			}
		},
		{
			slug: slugs.exam_result_list,
			text: "Result",
			image: "",
			params: {}
		},
		{
			slug: slugs.books,
			text: "Books",
			image: "",
			params: {}
		},
		{
			slug: "/profile",
			text: "Profile",
			image: "",
			params: {}
		}
	]
	const Block = ({ slug, text }) => {
		return (
			<div className="col-lg-4 col-md-6" >
				<Link style={{
					textDecorationLine: 'none',
					display: 'flex',
					justifyContent: 'center',
					// color: "#000",
				}}
					to={`${slug}`}
				>

					<div style={{
						backgroundColor: "#ffffff",
						display: 'flex',
						alignContent: 'center', alignItems: 'center',
						padding: "5px", margin: "10px 0px",
						width: "220px", height: "220px",

					}}
						className="home-box center"
					>
						<div style={{ backgroundColor: "#fff", border: "1px solid black", borderRadius: "5px" }}>
							<img
								style={{
									width: "220px", height: "200px",
									borderRadius: "5px"
								}}
								src={sideBarImage}
							/>
							<div style={{ color: "#000" }}>{text}</div>
						</div>
					</div>
				</Link>

			</div>
		)
	}
	return (
		<div>
			<div>
				<div className="row">
					<div className="col-1"></div>
					<div className="col-10 form-view mt-3">
						<div className="row" style={{ margin: "10px" }}>
							{HomeData.map((item, index) => (
								<Block slug={item.slug} text={item.text} />
							))}
						</div>
					</div>
				</div>
			</div >
		</div >
	)
}